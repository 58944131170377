import { useMemo, useState } from "react";
import NavbarCM from "../others/NavbarCM";
import Navbar from "../others/Navbar";
import Footer from "../others/Footer";
import { TipoPortal, link_youtube } from "../../../services/variables";
import "./styles.css";
import api from "../../../services/api";

export default function RedesSociais() {
  const [data, setData] = useState([]);

  async function getData() {
    try {
      const response = await api.get("/footer");
      setData(response.data.res);
    } catch (error) {
      console.log(error);
      return setData([]);
    }
  }

  useMemo(() => {
    getData();
  }, []);

  return (
    <main>
      {TipoPortal === 4 ? <NavbarCM /> : <Navbar />}
      <br />
      <div className="container">
        <h2 className="title-page">Nossas Redes Sociais Conectadas</h2>
        <p className="subtitulo">
          Explore além dos corredores municipais! descubra nossas redes sociais
          e mantenha-se atualizado com as últimas notícias, eventos e decisões
          importantes. Junte-se a nós online e faça parte do diálogo cívico. Sua
          participação faz a diferença!
        </p>
        <br />
        <br />
        {data.length === 0 && <p>Nenhuma rede cadastrada.</p>}
        {data.length > 0 &&
          data.map((item) => (
            <div className="social-container" key={item.ID}>
              {item.LINK_INSTAGRAM && (
                <a className="link" target="_blank" href={item.LINK_INSTAGRAM}>
                  <img
                    src="/icons/instagram.svg"
                    alt="logo Instagram"
                    className="svg-social"
                  />
                  <p className="text-social">Instagram</p>
                </a>
              )}
              {item.LINK_FACEBOOK && (
                <a className="link" target="_blank" href={item.LINK_FACEBOOK}>
                  <img
                    src="/icons/facebook.svg"
                    alt="logo Facebook"
                    className="svg-social"
                  />
                  <p className="text-social">Facebook</p>
                </a>
              )}
              {item.LINK_WHATSAPP && (
                <a className="link" target="_blank" href={item.LINK_WHATSAPP}>
                  <img
                    src="/icons/whatsapp.svg"
                    alt="logo whatsapp"
                    className="svg-social"
                  />
                  <p className="text-social">WhatsApp</p>
                </a>
              )}
              {link_youtube !== "#" && (
                <a href={link_youtube} target="_blank" className="link">
                  <img
                    src="/icons/youtube.svg"
                    alt="logo youtube"
                    className="svg-social youtube-icon"
                  />
                  <p className="text-social">Youtube</p>
                </a>
              )}
            </div>
          ))}
      </div>
      <br />
      <Footer />
    </main>
  );
}
